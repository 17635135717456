const login = {
	init: function () {
		if ( !document.querySelector( '#jdrf-global-login-user' ) ) {
			return false;
		}

		const menuItem = document.getElementById( 'jdrf-global-login-user' );
		const menuItems = document.querySelectorAll( '.jdrf-login-user' );
		const apiURL = menuItem.getAttribute( 'data-api_url' );

		const getUser = function( token ) {
			const xhr = new XMLHttpRequest();
			xhr.onload = function () {
				if ( 200 == xhr.status ) {
					Array.prototype.forEach.call( menuItems, function ( menuItem ) {
						const userName = menuItem.querySelector( '.jdrf-login-user__name' );
						const login = menuItem.parentNode.querySelector( '.jdrf-login' );
						login.classList.add( 'jdrf-login-user--hidden' );
						menuItem.classList.remove( 'jdrf-login-user--hidden' );
						const res = ( 'object' === typeof xhr.response ) ? xhr.response : JSON.parse( xhr.response );
						userName.getElementsByTagName( 'span' )[0].innerHTML = `<span class="first">${res.getConsResponse.name.first}</span><span class="last"> ${res.getConsResponse.name.last}</span>`;
					} );
				}
			};
			xhr.open( 'POST', `${apiURL}&method=getUser&auth=${token}` );
			xhr.withCredentials = true;
			xhr.responseType = 'json';
			xhr.send();
		};

		const xhr = new XMLHttpRequest();
		xhr.onload = function () {
			if ( 200 == xhr.status ) {
				const res = ( 'object' === typeof xhr.response ) ? xhr.response : JSON.parse( xhr.response ); getUser( res.getLoginUrlResponse.token );
			}
		};
		xhr.open( 'POST', `${apiURL}&method=getLoginUrl` );
		xhr.withCredentials = true;
		xhr.responseType = 'json';
		xhr.send();
	},
};

export default login;
