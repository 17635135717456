import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import ResetButton from './ResetButton';
import Downshift from 'downshift';
import FetchSearchResults from './FetchSearchResults';
import * as SwiftypeAppSearch from 'swiftype-app-search-javascript';

class SearchBox extends PureComponent {
	state = {
		value: '',
	};

	static propTypes = {
		id: PropTypes.string,
		text: PropTypes.string,
		eventType: PropTypes.string,
	};

	textInput = React.createRef();

	inputFocus() {
		document.body.classList.add( 'jdrf-search-focus' );
	}

	inputBlur() {
		document.body.classList.remove( 'jdrf-search-focus' );
	}

	client = SwiftypeAppSearch.createClient( {
		hostIdentifier: HOST_IDENTIFIER,
		apiKey: API_KEY,
		engineName: ENGINE_NAME,
	} );

	onChange = ( item ) => {
		if ( item ) {
			this.client
				.click( {
					query: item.query,
					documentId: item.data.id.raw,
					tags: ['Header']
				} )
				.then( () => {
					window.location.href = item.data.permalink.raw;
				} )
				.catch( error => {
					console.log( `error: ${error}` ); // eslint-disable-line no-console
				} );

		} else {
			this.textInput.current.focus();
		}
	};

	render() {
		const {value} = this.state;
		return (
			<Downshift
				itemToString={i => ( i ? i.name : i )}
				onChange={this.onChange}
				defaultHighlightedIndex={0}
				selectedItem={value}
			>
				{
					( {
							  getLabelProps,
							  getInputProps,
							  inputValue,
							  clearItems,
							  setHighlightedIndex,
							  isOpen,
							  getItemProps,
							  getMenuProps,
							  selectedItem,
							  highlightedIndex,
							  getToggleButtonProps,
							  closeMenu,
						  clearSelection,
						  } ) =>
						<div
							className="spirit-form__field-group spirit-form__input spirit-form__input--with-icon">
							<label {...getLabelProps(
								{
									htmlFor: this.props.id,
									className: 'spirit-form__label spirit-form__label-accessible-text',
								},
							) }>{this.props.text}</label>
							<div className="spirit-form__input-icon-wrap -left">
								<svg xmlns="http://www.w3.org/2000/svg"
									className="spirit-icon spirit-form__input-icon"
									viewBox="0 0 24 24">
									<path d="M11,20c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C20,16,16,20,11,20z M11,4c-3.9,0-7,3.1-7,7c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7 C18,7.1,14.9,4,11,4z"/><path d="M21,22c-0.3,0-0.5-0.1-0.7-0.3l-4.3-4.3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l4.3,4.3c0.4,0.4,0.4,1,0,1.4 C21.5,21.9,21.3,22,21,22z"/>
								</svg>
							</div>
							<input
								{...getInputProps( {
									ref: this.textInput,
									placeholder: this.props.text,
									id: this.props.id,
									className: 'spirit-form__input-field',
									value: inputValue,
									onFocus: this.inputFocus,
									onBlur: this.inputBlur,
								} )}
							/>
							<input type="submit" value="Submit" className="spirit-form__label-accessible-text" />
							{!isOpen ? null : (
								<Fragment>
									<ResetButton getToggleButtonProps={getToggleButtonProps}
									             focusHandler={this.inputFocus}
									             blurHandler={this.inputBlur}
												       text={this.props.text}
												       clearSelection={clearSelection}
									/>
									<FetchSearchResults
										searchValue={inputValue}
										onLoaded={() => {
											clearItems();
											setHighlightedIndex( 0 );
										}}
										limit={6}
										getItemProps={getItemProps}
										getMenuProps={getMenuProps}
										highlightedIndex={highlightedIndex}
										selectedItem={selectedItem}
										closeMenu={closeMenu}
										client={this.client}
										eventType={ this.props.eventType }
									/>
								</Fragment>
							)}
						</div>
				}
			</Downshift>

		);
	}
}

export default SearchBox;
