/**
 * Script for the navigation menu.
 *
 * This will be distributed along with the menu HTML and stylesheet.
 *
 * @package JdrfGlobalHeaderApi
 * @since 0.1.0
 */
import parentmenu from './components/parentmenu';
import submenu from './components/submenu';
import moremenu from './components/moremenu';
import toggle from './components/toggle';
import login from './components/login';
import SearchComponent from './components/search/SearchComponent';
import ReactDOM from 'react-dom';
import React from 'react';

const domContainer = document.querySelector( '#jdrf-global-search-form' );
if ( domContainer ) {
	ReactDOM.render( <SearchComponent eventType={ domContainer.dataset.eventType } />, domContainer );
}
parentmenu.init();
submenu.init();
moremenu.init();
toggle.init();
login.init();
