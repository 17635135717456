import React from 'react';
import PropTypes from 'prop-types';

const ResetButton = ( props ) => {
	return (
		<div className="spirit-form__input-icon-wrap">
			<button {...props.getToggleButtonProps( {
				className: 'jdrf-button-reset clear-selection',
			} )}  onFocus={props.focusHandler} onBlur={props.blurHandler} onClick={props.clearSelection}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					className="spirit-icon spirit-form__input-icon"
				>
					<path d="M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1s11,4.9,11,11S18.1,23,12,23z M12,3c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9 C21,7,17,3,12,3z"/><path d="M9,16c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l6-6c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-6,6C9.5,15.9,9.3,16,9,16z"/><path d="M15,16c-0.3,0-0.5-0.1-0.7-0.3l-6-6c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4C15.5,15.9,15.3,16,15,16z"/>
				</svg>
				<span
					className="spirit-form__label-accessible-text">
					{props.text}
				</span>
			</button>
		</div>

	);
};

ResetButton.propTypes = {
	text: PropTypes.string,
	focusHandler: PropTypes.func,
	blurHandler: PropTypes.func,
	getToggleButtonProps: PropTypes.func,
	clearSelection: PropTypes.func,
};

export default ResetButton;
