import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SearchBox from './SearchBox';

class SearchComponent extends PureComponent {

	static propTypes = {
		eventType: PropTypes.string,
	};

	render() {
		return (
			<form>
				<SearchBox
					id='jdrf-global-header-search'
					text='Search'
					eventType={ this.props.eventType }
				/>
			</form>
		);
	}

}

export default SearchComponent;
