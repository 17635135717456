const submenu = {
	init: function () {
		// return if the menu does not exist
		if ( !document.querySelector( '.jdrf-megamenu__parent > a' ) ) {
			return false;
		}

		const toggles = document.querySelectorAll( '.jdrf-megamenu__parent > a' );

		for ( let j = 0; j < toggles.length; j++ ) {
			const dropdown = toggles[j].nextElementSibling;
			dropdown.id = `jdrf-megamenu__sub-${  j}`;
			toggles[j].setAttribute( 'aria-controls', `jdrf-megamenu__sub-${  j}` );
		}

		// setup menu on load and resize
		window.addEventListener( 'load', submenu.setupResponsive );
		window.addEventListener( 'resize', submenu.setupResponsive );
	},

	setupResponsive: function() {
		const globalHeaderInner = document.querySelector( '.jdrf-global-header-inner' );
		let navComponents;

		if ( globalHeaderInner ) {
			navComponents = globalHeaderInner.querySelectorAll( '.jdrf-global-main-nav__menu[data-break]' );
		}

		if ( navComponents ) {
			Array.prototype.forEach.call( navComponents, function ( navComponent ) {
				const navBreak = navComponent.hasAttribute( 'data-break' ) ? navComponent.getAttribute( 'data-break' ) : '1080px';
				const toggles = navComponent.querySelectorAll( '.jdrf-megamenu__parent > a' );
				const mediaMatch = `(min-width: ${navBreak})`;
				const largeMegaNavCreated = navComponent.classList.contains( '-large-meganav-created' );
				const smallMegaNavCreated = navComponent.classList.contains( '-small-meganav-created' );

				if ( !largeMegaNavCreated && window.matchMedia( mediaMatch ).matches ) {
					for ( let j = 0; j < toggles.length; j++ ) {
						const dropdown = toggles[j].nextElementSibling;
						submenu.largeAria( toggles[j], dropdown );
						toggles[j].removeEventListener( 'click', submenu.toggleHandler );
					}

					document.removeEventListener( 'click', submenu.hideAll );

					navComponent.classList.add( '-large-meganav-created' );
					navComponent.classList.remove( '-small-meganav-created' );

				} else if ( !smallMegaNavCreated && !window.matchMedia( mediaMatch ).matches ) {
					for ( let j = 0; j < toggles.length; j++ ) {
						const dropdown = toggles[j].nextElementSibling;
						submenu.smallAria( toggles[j], dropdown );
						toggles[j].addEventListener( 'click', submenu.toggleHandler );
					}

					document.addEventListener( 'click', submenu.hideAll );

					navComponent.classList.remove( '-large-meganav-created' );
					navComponent.classList.add( '-small-meganav-created' );
				}
			} );



		}
	},

	smallAria: function( toggleButton, dropdown ) {
		toggleButton.classList.remove( '-large-expanded' );
		submenu.hide( toggleButton, dropdown );
	},

	largeAria: function( toggleButton, dropdown ) {
		toggleButton.classList.add( '-large-expanded' );

		const actionableItems = dropdown.querySelectorAll( 'a, button, input' );

		dropdown.removeAttribute( 'aria-hidden' );
		dropdown.classList.remove( 'jdrf-megamenu__sub--active' );
		toggleButton.setAttribute( 'aria-expanded', true );

		submenu.skipLinks( actionableItems );
	},

	toggleHandler: function( e ) {
		const toggleButton = e.target;
		const isExpanded = 'true' === toggleButton.getAttribute( 'aria-expanded' );
		const dropdown = document.getElementById( toggleButton.getAttribute( 'aria-controls' ) );

		e.preventDefault();
		e.stopPropagation();

		if ( ! dropdown ) {
			return false;
		}

		if ( isExpanded ) {
			submenu.hide( toggleButton, dropdown );
		} else {
			submenu.hideAll( e );
			submenu.open( toggleButton, dropdown );
		}
	},

	open: function( toggleButton, dropdown ) {
		const actionableItems = dropdown.querySelectorAll( 'a, button, input' );

		toggleButton.setAttribute( 'aria-expanded', true );
		dropdown.removeAttribute( 'aria-hidden' );
		dropdown.classList.add( 'jdrf-megamenu__sub--active' );

		submenu.tabLinks( actionableItems );

		// Focus on the first actionable item
		if ( actionableItems.length ) {
			actionableItems[0].focus();
		}
	},

	hide: function( toggleButton, dropdown ) {
		const actionableItems = dropdown.querySelectorAll( 'a, button, input' );

		toggleButton.setAttribute( 'aria-expanded', false );
		dropdown.setAttribute( 'aria-hidden', true );
		dropdown.classList.remove( 'jdrf-megamenu__sub--active' );

		submenu.skipLinks( actionableItems );
	},

	hideAll: function( e ) {
		const expandedToggles = document.querySelectorAll( '.jdrf-megamenu__parent > a[aria-expanded="true"]' );
		for ( let j = 0; j < expandedToggles.length; j++ ) {
			const isExpanded = 'true' === expandedToggles[j].getAttribute( 'aria-expanded' );
			const dropdown = document.getElementById( expandedToggles[j].getAttribute( 'aria-controls' ) );
			if ( !dropdown.contains( e.target ) && isExpanded ) {
				submenu.hide( expandedToggles[j], dropdown );
			}
		}
	},

	skipLinks: function( links ) {
		for ( let j = 0; j < links.length; j++ ) {
			links[j].setAttribute( 'tabindex', -1 );
		}
	},

	tabLinks: function( links ) {
		for ( let j = 0; j < links.length; j++ ) {
			links[j].removeAttribute( 'tabindex' );
		}
	},
};

export default submenu;
