import React from 'react';
import PropTypes from 'prop-types';

const TypeIcon = ( { icon } ) => {
	if ( ['jdrf-landing-page', 'jdrf_press_release', 'page', 'post'].includes( icon ) ) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="spirit-icon tablet-up" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"/><polyline points="13 2 13 9 20 9"/></svg>
		);
	} else if ( 'person' === icon ) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="spirit-icon tablet-up" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>
		);
	} else if ( 'team' === icon ) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="spirit-icon tablet-up" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M23 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75"/></svg>
		);
	} else if ( ['event', 'jdrf_event'].includes( icon ) ) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="spirit-icon tablet-up" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M19,23H5c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v14C22,21.7,20.7,23,19,23z M5,5C4.4,5,4,5.4,4,6v14 c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H5z"></path><path d="M16 7c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1s1 .4 1 1v4C17 6.6 16.6 7 16 7zM8 7C7.4 7 7 6.6 7 6V2c0-.6.4-1 1-1s1 .4 1 1v4C9 6.6 8.6 7 8 7zM21 11H3c-.6 0-1-.4-1-1s.4-1 1-1h18c.6 0 1 .4 1 1S21.6 11 21 11z"></path></svg>
		);
	} else if ( 'chapter_landing_page' === icon ) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="spirit-icon tablet-up" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
		);
	}
	return false;
};

TypeIcon.propTypes = {
	icon: PropTypes.string,
};

export default TypeIcon;
