// Media query for the small screen menu
const mediaQuery = '(max-width: 1079px)';
let moremenuCreated = false;

const moremenu = {
	init: function () {
		// return if the more menu does not exist
		if ( !document.querySelector( '.jdrf-global-main-nav__menu-item--more' ) ) {
			return false;
		}

		// setup menu on load and resize
		window.addEventListener( 'load', moremenu.createMoreMenu );
		window.addEventListener( 'resize', moremenu.createMoreMenu );
	},

	shouldCreateMenu: function() {
		const header = document.querySelector( '.jdrf-global-header' );
		const headerVariation = header.getAttribute( 'data-header-variation' );

		return 'default' !== headerVariation;
	},

	createMoreMenu: function() {

		const shouldCreateMenu
			= moremenu.shouldCreateMenu()
			|| window.matchMedia( mediaQuery ).matches;

		// Create the menu only once
		if ( !moremenuCreated && shouldCreateMenu ) {

			const moreMenu = document.querySelector(
				'.jdrf-global-main-nav__menu-item--more'
			);

			const moreMenuTopLinks = moreMenu.querySelectorAll(
				'.jdrf-megamenu__column > li > a'
			);

			const navContainerInner = document.getElementById(
				'jdrf-global-main-nav__inner'
			);

			const moreMenuWrapper = document.createElement( 'div' );

			moreMenuWrapper.id = 'jdrf-global-main-nav__more-menu';
			moreMenuWrapper.classList.add( 'jdrf-global-main-nav__more-menu' );

			for ( let j = 0; j < moreMenuTopLinks.length; j++ ) {
				const link = moreMenuTopLinks[j].cloneNode( true );

				if ( 'Overview' === link.text ) {
					continue;
				}

				link.removeAttribute( 'aria-controls' );
				link.removeAttribute( 'aria-expanded' );
				link.removeAttribute( 'tabindex' );
				moreMenuWrapper.append( link );
			}

			navContainerInner.append( moreMenuWrapper );

			moremenuCreated = true;
		}
	},
};

export default moremenu;
