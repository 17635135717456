import submenu from './submenu';
import toggle from './toggle';

// Media query for the small screen menu
const mediaQuery = '(max-width: 1079px)';

/**
 * Nodelist forEach polyfill
 * https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
 */
if ( window.NodeList && !NodeList.prototype.forEach ) {
	NodeList.prototype.forEach = function ( callback, thisArg ) {
		thisArg = thisArg || window;
		for ( let i = 0; i < this.length; i++ ) {
			callback.call( thisArg, this[i], i, this );
		}
	};
}

const parentmenu = {
	init: function () {
		// return if the menu does not exist
		if ( !document.querySelector( '.jdrf-global-main-nav__menu' ) ) {
			return false;
		}

		parentmenu.setupMenuItemPopups();

		document.addEventListener( 'click', parentmenu.hideAll );

		// setup menu on load and resize
		window.addEventListener( 'load', parentmenu.setupResponsive );
		window.addEventListener( 'resize', parentmenu.setupResponsive );
	},

	setupMenuItemPopups: function() {
		const toggles = document.querySelectorAll( '.jdrf-global-main-nav__menu-item.menu-item-has-children .jdrf-global-main-nav__link--top-level' );

		for ( let j = 0; j < toggles.length; j++ ) {
			const dropdown = toggles[j].nextElementSibling;
			if ( dropdown ) {
				const dropdownLinks = dropdown.querySelectorAll( 'a' );
				const lastdropdownLink = dropdownLinks[dropdownLinks.length - 1];
				lastdropdownLink.addEventListener( 'keydown', parentmenu.tabClose );
				dropdown.id = `jdrf-global-main-nav__megamenu-${  j}`;
				toggles[j].setAttribute( 'aria-controls', `jdrf-global-main-nav__megamenu-${  j}` );
				toggles[j].setAttribute( 'aria-haspopup', 'true' );
			}
		}
	},

	setupResponsive: function() {

		const globalHeaderInner = document.querySelector(
			'.jdrf-global-header-inner'
		);

		if ( ! globalHeaderInner ) {
			return;
		}

		const navComponents = globalHeaderInner.querySelectorAll(
			'.jdrf-global-main-nav__menu[data-break]'
		);

		if ( ! navComponents ) {
			return;
		}

		navComponents.forEach( function ( navComponent ) {

			const navBreak = navComponent.hasAttribute( 'data-break' )
				? navComponent.getAttribute( 'data-break' )
				: '1080px';

			const toggles = navComponent.querySelectorAll(
				'.jdrf-global-main-nav__menu-item.menu-item-has-children .jdrf-global-main-nav__link--top-level'
			);

			const isTouch
				= ( 'ontouchstart' in window ) || window.DocumentTouch
					&& document instanceof window.DocumentTouch;

			const mediaMatch = `(min-width: ${navBreak})`;

			const largeNavCreated = navComponent.classList.contains(
				'-large-nav-created'
			);

			const smallNavCreated = navComponent.classList.contains( '-small-nav-created' );

			if ( !largeNavCreated && window.matchMedia( mediaMatch ).matches ) {
				for ( let j = 0; j < toggles.length; j++ ) {

					const dropdown = toggles[j].nextElementSibling;

					if ( dropdown ) {
						parentmenu.largeAria( toggles[j], dropdown );
						toggles[j].addEventListener( 'keydown', parentmenu.keydownToggleHandler, true );

						if ( !isTouch ) {
							toggles[j].removeEventListener( 'click', parentmenu.toggleHandler, true );
							toggles[j].parentNode.addEventListener( 'mouseenter', parentmenu.hoverOpen, true );
							toggles[j].parentNode.addEventListener( 'mouseleave', parentmenu.hoverHide, true );

						} else {
							document.body.classList.add( 'is-touch' );
							toggles[j].addEventListener( 'click', parentmenu.toggleHandler, true );

						}

					}
				}

				navComponent.classList.add( '-large-nav-created' );
				navComponent.classList.remove( '-small-nav-created' );

			} else if (
				!smallNavCreated
				&& !window.matchMedia( mediaMatch ).matches
			) {
				for ( let j = 0; j < toggles.length; j++ ) {
					const dropdown = toggles[j].nextElementSibling;
					if ( dropdown ) {
						parentmenu.smallAria( toggles[j], dropdown );

						toggles[j].addEventListener(
							'click',
							parentmenu.toggleHandler,
							true
						);

						toggles[j].removeEventListener(
							'keydown',
							parentmenu.keydownToggleHandler,
							true
						);

						if ( !isTouch ) {
							toggles[j].parentNode.removeEventListener(
								'mouseenter',
								parentmenu.hoverOpen,
								true
							);

							toggles[j].parentNode.removeEventListener(
								'mouseleave',
								parentmenu.hoverHide,
								true
							);

						}
					}
				}
				navComponent.classList.remove( '-large-nav-created' );
				navComponent.classList.add( '-small-nav-created' );
			}

		} );
	},

	smallAria: function( toggleButton, dropdown ) {
		const actionableItems = dropdown.querySelectorAll(
			'.jdrf-megamenu__column > li > a'
		);

		parentmenu.hide( toggleButton, dropdown );
		parentmenu.skipLinks( actionableItems );
	},

	largeAria: function( toggleButton, dropdown ) {
		const actionableItems = dropdown.querySelectorAll( 'a' );

		dropdown.setAttribute( 'aria-hidden', true );
		dropdown.classList.remove( 'jdrf-megamenu--active' );
		toggleButton.setAttribute( 'aria-expanded', false );

		parentmenu.skipLinks( actionableItems );
	},

	keydownToggleHandler: function( e ) {
		const toggleButton = e.target;
		const isExpanded = 'true' === toggleButton.getAttribute( 'aria-expanded' );
		const dropdown = document.getElementById(
			toggleButton.getAttribute( 'aria-controls' )
		);

		if ( ! dropdown ) {
			return false;
		}

		if ( 13 === e.keyCode ) {
			// pressing enter on a parent link
			parentmenu.toggleHandler( e );
		} else if ( 9 === e.keyCode && e.shiftKey ) {
			// shift tabbing a parent link
			// close megamenu if opened
			if ( isExpanded ) {
				parentmenu.hide( toggleButton, dropdown );
			}
		}
	},

	toggleHandler: function( e ) {
		const toggleButton = e.target;
		const isExpanded = 'true' === toggleButton.getAttribute( 'aria-expanded' );
		const dropdown = document.getElementById( toggleButton.getAttribute( 'aria-controls' ) );

		e.preventDefault();
		e.stopPropagation();

		if ( ! dropdown ) {
			return false;
		}

		if ( isExpanded ) {
			parentmenu.hide( toggleButton, dropdown );
		} else {
			parentmenu.hideAll( e );
			parentmenu.hideToggles( e );
			parentmenu.open( toggleButton, dropdown, e );
		}
	},

	open: function( toggleButton, dropdown, e ) {
		let actionableItems;
		if ( window.matchMedia( mediaQuery ).matches ) {
			actionableItems = dropdown.querySelectorAll( '.jdrf-megamenu__column > li > a' );
		} else {
			actionableItems = dropdown.querySelectorAll( 'a' );
		}

		toggleButton.setAttribute( 'aria-expanded', true );
		dropdown.removeAttribute( 'aria-hidden' );
		dropdown.classList.add( 'jdrf-megamenu--active' );

		parentmenu.tabLinks( actionableItems );

		if ( e && ( 'keydown' === e.type || 'click' === e.type ) ) {
			// Focus on the first actionable item
			if ( actionableItems.length ) {
				actionableItems[0].focus();
			}
		}
	},

	hoverOpen: function( e ) {
		e.preventDefault();
		let toggleButton;
		if ( 'LI' === e.target.tagName ) {
			toggleButton = e.target.querySelector( 'a' );
		} else {
			toggleButton = e.target;
		}

		if (
			! toggleButton.parentNode.parentNode.classList.contains(
				'jdrf-global-main-nav__menu', '-nav-created'
			)
		) {
			return false;
		}

		const isExpanded = 'true' === toggleButton.getAttribute( 'aria-expanded' );
		const dropdown = document.getElementById(
			toggleButton.getAttribute( 'aria-controls' )
		);

		if ( ! dropdown ) {
			return false;
		}

		if ( !isExpanded ) {
			parentmenu.open( toggleButton, dropdown );
		}
	},

	hide: function( toggleButton, dropdown ) {
		let actionableItems;
		if ( window.matchMedia( mediaQuery ).matches ) {
			actionableItems = dropdown.querySelectorAll( '.jdrf-megamenu__column > li > a' );
		} else {
			actionableItems = dropdown.querySelectorAll( 'a' );
		}

		const expandedSubmenus = document.querySelectorAll( '.jdrf-megamenu__parent > a[aria-expanded="true"]' );
		if ( expandedSubmenus.length ) {
			for ( let j = 0; j < expandedSubmenus.length; j++ ) {
				const submenu = document.getElementById( expandedSubmenus[j].getAttribute( 'aria-controls' ) );
				parentmenu.hideSubmenu( expandedSubmenus[j], submenu );
			}
		}

		toggleButton.setAttribute( 'aria-expanded', false );
		dropdown.setAttribute( 'aria-hidden', true );
		dropdown.classList.remove( 'jdrf-megamenu--active' );

		parentmenu.skipLinks( actionableItems );
	},

	hoverHide: function( e ) {
		e.preventDefault();

		let toggleButton;
		if ( 'LI' !== e.target.tagName ) {
			return false;
		} else {
			toggleButton = e.target.querySelector( 'a' );
		}

		if ( ! toggleButton.parentNode.parentNode.classList.contains( 'jdrf-global-main-nav__menu', '-nav-created' ) ) {
			return false;
		}

		const isExpanded = 'true' === toggleButton.getAttribute( 'aria-expanded' );
		const dropdown = document.getElementById( toggleButton.getAttribute( 'aria-controls' ) );

		if ( ! dropdown ) {
			return false;
		}

		if ( isExpanded ) {
			parentmenu.hide( toggleButton, dropdown );
		}
	},

	hideAll: function( e ) {
		const expandedToggles = document.querySelectorAll( '.jdrf-global-main-nav__menu-item.menu-item-has-children > a[aria-expanded="true"]' );
		for ( let j = 0; j < expandedToggles.length; j++ ) {
			const isExpanded = 'true' === expandedToggles[j].getAttribute( 'aria-expanded' );
			const dropdown = document.getElementById( expandedToggles[j].getAttribute( 'aria-controls' ) );
			if ( e ) {
				if ( ( !dropdown.contains( e.target ) && isExpanded ) || 9 === e.keyCode ) {
					parentmenu.hide( expandedToggles[j], dropdown );
				}
			} else {
				parentmenu.hide( expandedToggles[j], dropdown );
			}
		}
	},

	skipLinks: function( links ) {
		for ( let j = 0; j < links.length; j++ ) {
			links[j].setAttribute( 'tabindex', -1 );
		}
	},

	tabLinks: function( links ) {
		for ( let j = 0; j < links.length; j++ ) {
			links[j].removeAttribute( 'tabindex' );
		}
	},

	hideSubmenu: function( button, dropdown ) {
		// Make sure not explicetly expanded on larger breakpoints
		if( ! button.classList.contains( '-large-expanded' ) ) {
			return submenu.hide( button, dropdown );
		}
	},

	hideToggles: function( e ) {
		return toggle.hideAll( e );
	},

	tabClose: function( e ) {
		if ( !window.matchMedia( mediaQuery ).matches ) {
			if ( 9 === e.keyCode && e.shiftKey ) {
				// do nothing, we're shift tabbing
			} else if ( 9 === e.keyCode ) {
				parentmenu.hideAll( e );
			}
		}
	}
};

export default parentmenu;
