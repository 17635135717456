import React, {Component} from 'react';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import TypeIcon from './TypeIcon';

class FetchSearchResults extends Component {
	static propTypes = {
		limit: PropTypes.number,
		searchValue: PropTypes.string,
		onLoaded: PropTypes.func,
		getItemProps: PropTypes.func,
		getMenuProps: PropTypes.func,
		highlightedIndex: PropTypes.number,
		selectedItem: PropTypes.string,
		closeMenu: PropTypes.func,
		client: PropTypes.object,
		eventType: PropTypes.string,
	};
	state = {loading: false, error: null, results: {}};

	mounted = false;

	fetch = debounce( () => {
		if ( !this.mounted ) {
			return;
		}
		if ( '' === this.props.searchValue ) {
			this.props.closeMenu();
			return;
		}

		const options = {
			page: {
				size: this.props.limit,
				current: 1,
			},
			result_fields: { // eslint-disable-line
				id: {raw: {}},
				title: {raw: {}, snippet: {size: 100, fallback: true}},
				permalink: {raw: {}},
				content_type: {raw: {}}, // eslint-disable-line
				event_name: {raw: {}}, // eslint-disable-line
				event_city: {raw: {}}, // eslint-disable-line
				event_state: {raw: {}} // eslint-disable-line
			},
			filters: {
				// filtering out all "Chapter Team" participants due to task https://www.wrike.com/workspace.htm?acc=1797000#path=folder&id=291661447&a=1797000&c=board&ot=292443088&so=10&bso=10&sd=0&st=nt-1
				none: [
					{
						all: [
							{title: 'Chapter Team'},
							{content_type: 'person'} // eslint-disable-line camelcase
						]
					}
				]

			}
		};

		// Boost searches for specific event types
		if ( this.props.eventType ) {
			options.boosts = {
				'event_type': [
					{
						'type': 'value',
						'value': '',
						'operation': 'multiply',
						'factor': 5
					}
				]
			};

			switch( this.props.eventType ) {
					case 'challenge':
						options.boosts.event_type[0].value = 'Challenge';
						break;
					case 'diy':
						options.boosts.event_type[0].value = 'Your Way';
						break;
					case 'ride':
						options.boosts.event_type[0].value = 'Ride';
						break;
					case 'walk':
						options.boosts.event_type[0].value = 'Walk';
						break;
			}
		}

		this.props.client.search( this.props.searchValue, options ).then(
			( response ) => {
				const results = response.results;

				if ( results.length ) {
					results.push(
						{
							data : {
								id : {
									raw : 'WP_ALL_RESULTS'
								},
								title : {
									raw: 'View search results'
								},
								permalink: {
									raw: `${SEARCH_PAGE  }?s=${  encodeURIComponent( this.props.searchValue )}`
								},
								content_type: { raw: 'link' }, // eslint-disable-line camelcase
								className: { raw: 'view-all' }
							}
						}
					);
					// Add query to results. it will be needed for tracking.
					results.map( ( result ) => {
						result.query = this.props.searchValue;
						return result;
					} );
				}

				if ( this.mounted ) {
					this.props.onLoaded();
					this.setState( {loading: false, results} );
				}
			},
			( error ) => {
				if ( this.mounted ) {
					this.props.onLoaded();
					this.setState( {loading: false, error} );
				}
			},
		);
	}, 300 );

	componentDidMount() {
		this.mounted = true;
		this.setState(
			{
				loading: true,
			},
			() => {
				this.fetch();
			},
		);
	}

	componentDidUpdate( prevProps ) {
		if (
			prevProps.searchValue !== this.props.searchValue
		) {
			this.setState(
				{
					loading: true,
				},
				() => {
					this.fetch();
				},
			);
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	getStateName( input ) {
		const states = [
			['Alabama', 'AL'],
			['Alaska', 'AK'],
			['American Samoa', 'AS'],
			['Arizona', 'AZ'],
			['Arkansas', 'AR'],
			['Armed Forces Americas', 'AA'],
			['Armed Forces Europe', 'AE'],
			['Armed Forces Pacific', 'AP'],
			['California', 'CA'],
			['Colorado', 'CO'],
			['Connecticut', 'CT'],
			['Delaware', 'DE'],
			['District Of Columbia', 'DC'],
			['Florida', 'FL'],
			['Georgia', 'GA'],
			['Guam', 'GU'],
			['Hawaii', 'HI'],
			['Idaho', 'ID'],
			['Illinois', 'IL'],
			['Indiana', 'IN'],
			['Iowa', 'IA'],
			['Kansas', 'KS'],
			['Kentucky', 'KY'],
			['Louisiana', 'LA'],
			['Maine', 'ME'],
			['Marshall Islands', 'MH'],
			['Maryland', 'MD'],
			['Massachusetts', 'MA'],
			['Michigan', 'MI'],
			['Minnesota', 'MN'],
			['Mississippi', 'MS'],
			['Missouri', 'MO'],
			['Montana', 'MT'],
			['Nebraska', 'NE'],
			['Nevada', 'NV'],
			['New Hampshire', 'NH'],
			['New Jersey', 'NJ'],
			['New Mexico', 'NM'],
			['New York', 'NY'],
			['North Carolina', 'NC'],
			['North Dakota', 'ND'],
			['Northern Mariana Islands', 'NP'],
			['Ohio', 'OH'],
			['Oklahoma', 'OK'],
			['Oregon', 'OR'],
			['Pennsylvania', 'PA'],
			['Puerto Rico', 'PR'],
			['Rhode Island', 'RI'],
			['South Carolina', 'SC'],
			['South Dakota', 'SD'],
			['Tennessee', 'TN'],
			['Texas', 'TX'],
			['US Virgin Islands', 'VI'],
			['Utah', 'UT'],
			['Vermont', 'VT'],
			['Virginia', 'VA'],
			['Washington', 'WA'],
			['West Virginia', 'WV'],
			['Wisconsin', 'WI'],
			['Wyoming', 'WY'],
		];
		for ( let i = 0; i < states.length; i++ ) {
			if ( states[i][1] == input ) {
				return ( states[i][0] );
			}
		}
		return input;
	}

	render() {
		const {loading, results, error} = this.state;

		return (
			<div {...this.props.getMenuProps(
				{className: 'jdrf-search-dropdown'} )} >
				{loading ? (
					<div className='loading'>
						<span className="spirit-button__icon-contain">
							<svg aria-label="Loading" role="img" className="spirit-icon--spinner" viewBox="25 25 50 50" xmlns="http://www.w3.org/2000/svg"><circle className="spirit-icon--spinner__path" cx="50" cy="50" r="20" strokeWidth="4" strokeMiterlimit="10"></circle></svg>
						</span>
						<span className="spirit-screen-reader-text">loading...</span>
					</div>
				) : error ? (
					<div className='error'>error...</div>
				) : results.length ? (
					results.map( ( item, index ) => {
						const classes = ['jdrf-result-item'];
						let title = item.data.title.raw;

						if ( this.props.highlightedIndex === index ) {
							classes.push( 'highlighted' );
						}

						if ( item.data.className ) {
							classes.push( item.data.className.raw );
						}

						if ( item.data.title.snippet ) {
							title = item.data.title.snippet;
							title = title.replace( /<em>/g, '<strong>' );
							title = title.replace( /<\/em>/g, '</strong>' );
						}

						if ( 'jdrf_event' === item.data.content_type.raw && ( item.data.hasOwnProperty( 'event_city' ) || item.data.hasOwnProperty( 'event_state' ) ) ) {
							let contextName = [];
							if ( item.data.event_city.raw ) {
								contextName.push( item.data.event_city.raw );
							}
							if ( item.data.event_state.raw ) {
								contextName.push( this.getStateName( item.data.event_state.raw ) );
							}
							contextName = contextName.join( ', ' );
							if ( contextName ) {
								title += `<em> - ${  contextName  }</em>`;
							}
						}

						if ( ( 'team' === item.data.content_type.raw || 'person' === item.data.content_type.raw ) && item.data.hasOwnProperty( 'event_name' ) ) {
							title += `<em> - ${  item.data.event_name.raw  }</em>`;
						}

						return (
							<div
								key={item.data.id.raw}
								{...this.props.getItemProps( {
									item,
									index,
									className: classes.join( ' ' ),
								} )
								}
							>
								<TypeIcon icon={item.data.content_type.raw}/>
								<span dangerouslySetInnerHTML={{__html: title}} />
							</div>
						);
					},
					)
				) : (
					<div>No results.</div>
				)}
			</div>
		);
	}
}

export default FetchSearchResults;
