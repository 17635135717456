import parentmenu from './parentmenu';

const toggle = {
	init: function () {
		// return if the menu does not exist
		if ( !document.querySelector( '.js-toggle' ) ) {
			return false;
		}

		const toggles = document.querySelectorAll( '.js-toggle' );

		for ( let j = 0; j < toggles.length; j++ ) {
			toggles[j].addEventListener( 'click', this.toggleHandler );
		}

		document.addEventListener( 'click', this.hideAll );
		window.addEventListener( 'resize', this.checkToHide );
	},

	toggleHandler: function( e ) {
		let toggleButton = e.target;
		const mainToggle = document.getElementById( 'jdrf-menu-button' );

		// Switch primary toggle button if internal toggle is used
		if( 'jdrf-menu-button-close' === toggleButton.id && mainToggle ) {
			toggleButton = mainToggle;
		}

		const isExpanded = 'true' === toggleButton.getAttribute( 'aria-expanded' );
		const dropdown = document.getElementById( toggleButton.getAttribute( 'aria-controls' ) );
		const header = document.querySelector( '.jdrf-global-header' );

		e.preventDefault();
		e.stopPropagation();

		if ( ! dropdown ) {
			return false;
		}

		if ( isExpanded ) {
			toggle.hide( toggleButton, dropdown, header );
		} else {
			toggle.hideAll( e );
			toggle.open( toggleButton, dropdown,header );
		}
	},

	open: function( toggleButton, dropdown, header  ) {
		const customClass = toggleButton.getAttribute( 'data-class' );
		const customHeaderClass = toggleButton.getAttribute( 'data-header-class' );
		const focusIdOnClick = toggleButton.getAttribute( 'data-focus-id-on-click' );

		const mobileNav = document.getElementById( 'jdrf-global-main-nav' );

		if ( focusIdOnClick ) {
			const elToFocus = document.getElementById( focusIdOnClick );

			if ( elToFocus ) {
				// for some reason this will only focus on the next
				// event loop.
				setTimeout( () => {
					elToFocus.focus();
				}, 1 );
			}
		}

		/**
		 * focus on first item
		 * @return void
		 */
		const focusFirstItem = function( e ) {

			// Focus on close toggle when drawer open
			const closeToggle = document.getElementById( 'jdrf-menu-button-close' );

			if( closeToggle ) {
				closeToggle.focus();
			} else {
				// If no close toggle, focus on first visible link when drawer open
				const actionableItems = [].slice.call( dropdown.querySelectorAll( 'li' ) );

				// Wait 'til transition ends before focusing on first item
				if( mobileNav && actionableItems && e && 'transform' === e.propertyName ) {

				// Get all items that are visible links
					const actionableItemsVisible = actionableItems.filter( function ( actionableItem ) {
						const style = getComputedStyle( actionableItem );
						const display =  style.getPropertyValue( 'display' );
						const link = actionableItem.querySelectorAll( 'a' )[0];
						let validLink = false;

						if( link && !link.hasAttribute( 'tabindex' ) ) {
							validLink = true;
						}

						return 'none' !== display && validLink;
					} );

					const firstItem = actionableItemsVisible[0].querySelectorAll( 'a' )[0];

					if( firstItem ) {
						firstItem.focus();
					}
				}
			}

			mobileNav.removeEventListener( 'transitionend', focusFirstItem );
		};

		toggleButton.setAttribute( 'aria-expanded', true );
		dropdown.removeAttribute( 'aria-hidden' );
		dropdown.classList.add( 'js-expanded' );
		header.classList.add( '-nav-open' );
		document.documentElement.classList.add( '-spirit-no-scroll' );

		if ( customClass ) {
			dropdown.classList.add( customClass );
		}

		if ( customHeaderClass ) {
			header.classList.add( customHeaderClass );
		}

		// Focus on the first actionable item
		if ( mobileNav ) {
			mobileNav.addEventListener( 'transitionend', focusFirstItem, false );
			document.addEventListener( 'keydown', this.keydownListen );
		}

		parentmenu.hideAll( null );
	},

	hide: function( toggleButton, dropdown, header ) {
		const customClass = toggleButton.getAttribute( 'data-class' );
		const customHeaderClass = toggleButton.getAttribute( 'data-header-class' );

		toggleButton.setAttribute( 'aria-expanded', false );
		dropdown.setAttribute( 'aria-hidden', true );
		dropdown.classList.remove( 'js-expanded' );
		header.classList.remove( '-nav-open' );
		document.documentElement.classList.remove( '-spirit-no-scroll' );

		if ( customClass ) {
			dropdown.classList.remove( customClass );
		}

		if ( customHeaderClass ) {
			header.classList.remove( customHeaderClass );
		}

		document.removeEventListener( 'keydown', this.keydownListen );
		toggleButton.focus();
	},

	keydownListen: function( event ) {
		const mobileNav = document.getElementById( 'jdrf-global-main-nav' );

		if( !mobileNav ) {
			return;
		}

		let toggleButton = document.getElementById( 'jdrf-menu-button' );
		const closeToggle = document.getElementById( 'jdrf-menu-button-close' );

		const dropdown = toggleButton ? document.getElementById( toggleButton.getAttribute( 'aria-controls' ) ) : '';

		if ( dropdown && mobileNav.classList.contains( 'jdrf-global-main-nav--active' ) && ( 9 === event.which ) ) {
			const navLinks = dropdown.querySelectorAll( 'a' );
			const navLinksCount = navLinks.length -1;
			const lastLink = navLinks[navLinksCount];

			if ( lastLink === document.activeElement ) {
				event.preventDefault();

				// Switch primary close button to focus if exists
				if( closeToggle ) {
					toggleButton = closeToggle;
				}

				toggleButton.focus();
			} else if( toggleButton === document.activeElement && 'true'  === toggleButton.getAttribute( 'aria-expanded' ) ) {
				event.preventDefault();
				const actionableItems = [].slice.call( dropdown.querySelectorAll( 'li' ) );

				if( actionableItems ) {

					// Get all items that are visible links
					const actionableItemsVisible = actionableItems.filter( function ( actionableItem ) {
						const style = getComputedStyle( actionableItem );
						const display =  style.getPropertyValue( 'display' );
						const link = actionableItem.querySelectorAll( 'a' )[0];
						let validLink = false;

						if( link && !link.hasAttribute( 'tabindex' ) ) {
							validLink = true;
						}

						return 'none' !== display && validLink;
					} );

					const firstItem = actionableItemsVisible[0].querySelectorAll( 'a' )[0];

					if( firstItem ) {
						firstItem.focus();
					}
				}
			}
		} else if ( mobileNav.classList.contains( 'jdrf-global-main-nav--active' ) && ( 27 === event.which ) ) {
			toggle.hideAll( null );
		}
	},

	hideAll: function( e ) {
		const expandedToggles = document.querySelectorAll( '.js-toggle[aria-expanded="true"]' );
		const header = document.querySelector( '.jdrf-global-header' );
		for ( let j = 0; j < expandedToggles.length; j++ ) {
			const isExpanded = 'true' === expandedToggles[j].getAttribute( 'aria-expanded' );
			const dropdown = document.getElementById( expandedToggles[j].getAttribute( 'aria-controls' ) );
			if ( e ) {
				if ( !dropdown.contains( e.target ) && isExpanded && !e.target.classList.contains( 'clear-selection' ) ) {
					toggle.hide( expandedToggles[j], dropdown, header );
				}
			} else {
				toggle.hide( expandedToggles[j], dropdown, header );
			}
		}
	},
	checkToHide: function() {
		// Media query for the small screen menu
		const mediaQuery = '(max-width: 1079px)';
		// Check initially if the sub menu is created
		const parentmenuCreated = window.matchMedia( mediaQuery ).matches;

		if( ! parentmenuCreated ) {
			toggle.hideAll( null );
		}
	},
};

export default toggle;
